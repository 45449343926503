//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  otpError?: any;
  showSpinner?: boolean;
  otpToken?: boolean;
  timer: number;
  isResendClicked?: boolean;
  // Customizable Area Start
  cartId: any;
  cartDetails: any;
  productDetails: any;
  productToBeAdded: any;
  productInCart: any;
  itemQuantity: any;
  productDescriptionLoader:boolean;
  catalogue_variant_id:any;
  isProductAvailable?: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OTPConfirmationController extends BlockComponent<
  Props,
  S,
  SS
> {
  resendOTPEmailAPICallId: string;
  sendEmailOTPApiCallId: string;
  registerEmailAPICallId: string;
  // Customizable Area Start
  postCreateCartApiCallId: string = "";
  putItemToCartApiCallId: string = "";
  GetIsCartCreatedApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.AlertMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    const savedTimer = localStorage.getItem("timer");
    this.state = {
      timer: savedTimer ? savedTimer : 60,
      cartId: "",
      cartDetails: null,
      productDetails: null,
      productToBeAdded: "",
      productInCart: "",
      itemQuantity: 1,
      productDescriptionLoader:false,
      catalogue_variant_id:'',
      isProductAvailable: false,
    };
    this.timer = 60;
    this.receive = this.receive.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  startTimer() {
    const savedTimer = localStorage.getItem("timer");
    // @ts-ignore
    if ((savedTimer || this.timer == 60) && this.state.timer > 0) {
      // @ts-ignore
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // @ts-ignore
    let timer = this.state.timer - 1;
    localStorage.setItem("timer", timer);
    this.setState({
      timer: timer,
      showSpinner: false,
    });

    // Check if we're at zero.
    if (timer == 0) {
      localStorage.removeItem("timer");
      // @ts-ignore
      clearInterval(this.timer);
    }
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (apiRequestCallId === this.sendEmailOTPApiCallId) {
          if (responseJson && responseJson.data && responseJson.meta) {
            this.setState({
              showSpinner: false,
            });
            // @ts-ignore
            window.notify([
              {
                message: responseJson.meta && responseJson.meta.message,
                type: "success",
              },
            ]);
            const { data, meta } = responseJson;
            localStorage.setItem("token", meta.token);
            await this.SignUp();
          }
          if (
            responseJson &&
            responseJson.errors &&
            responseJson.errors.length > 0
          ) {
            this.setState({
              showSpinner: false,
            });
            // @ts-ignore
            window.notify([
              {
                message:
                  responseJson.errors[0].pin && responseJson.errors[0].pin,
                type: "danger",
              },
            ]);
          }
        } else if (apiRequestCallId === this.resendOTPEmailAPICallId) {
          if (responseJson && responseJson.data && responseJson.meta) {
            this.timer = 60;
            this.setState(
              {
                showSpinner: false,
                timer: 60,
              },
              this.startTimer
            );
            localStorage.setItem(
              "token",
              responseJson.meta.token && responseJson.meta.token
            );
            //@ts-ignore
            window.notify([
              { message: "OTP has been sent to you", type: "success" },
            ]);
          }
          if (
            responseJson &&
            responseJson.errors &&
            responseJson.errors.length > 0
          ) {
            this.setState({
              showSpinner: false,
            });
            // @ts-ignore
            window.notify([
              {
                message:
                  (responseJson.errors[0].account &&
                    responseJson.errors[0].account) ||
                  "Something went wrong",
                type: "danger",
              },
            ]);
          }
        } else if (apiRequestCallId === this.registerEmailAPICallId) {
          if (responseJson && responseJson.data && responseJson.meta) {
            const { data, meta } = responseJson;
            localStorage.setItem("user", JSON.stringify(data));
            let dat = {
              email:
                data.attributes?.type == "SmsAccount"
                  ? data.attributes?.full_phone_number
                  : data.attributes.email,
              name: data.attributes.full_name || data.attributes.first_name,
            };
            localStorage.setItem("userData", JSON.stringify(dat));
            localStorage.setItem(
              "profileImage",
              (data.attributes?.image_url && data.attributes?.image_url) ||
              ""
            );
            localStorage.setItem("token", meta?.token);
            //@ts-ignore
            window.notify([
              { message: "Registered Successfully !", type: "success" },
            ]);
            //@ts-ignore
            const guestId = localStorage.getItem("guestUUID");
            // let cartCatelog = JSON.parse(localStorage.getItem('cartCatelog') ?? "[]");
            // if(cartCatelog && cartCatelog?.length>0){
            //   cartCatelog?.map((cat_Item:any)=>{
            //     this.addToCart(cat_Item)
            //   })
            // }
            // const cart_length = parseInt(
            //   localStorage.getItem("cart_length") ?? "0"
            // );
            // if (guestId && cart_length!=0) {
            //     setTimeout(() => {
            //         this.props?.history?.push("/cart");
            //       }, 2500);
            // } else {
              setTimeout(() => {
                this.props?.history?.push("/home-page");
              }, 1000);
            // }
            localStorage.removeItem("signUpUser");
            localStorage.removeItem("userFullName");
          }
          if (
            responseJson &&
            responseJson.errors &&
            responseJson.errors.length > 0
          ) {
            this.setState({
              showSpinner: false,
            });
            // @ts-ignore
            window.notify([
              {
                message:
                  responseJson.errors[0].account ||
                  responseJson.errors[0].full_phone_number ||
                  "Something went wrong",
                type: "danger",
              },
            ]);
          }
        }else if (apiRequestCallId === this.postCreateCartApiCallId) {
          if (responseJson?.data) {
            //@ts-ignore
            window.notify([
              {
                message: "Item added in cart successfully",
                type: "success",
              },
            ]);
            //this.getIsCartCreated()
            var cartUpdateMessage = new Message(
              getName(MessageEnum.LoginGetCart)
            );
            cartUpdateMessage.addData(
              getName(MessageEnum.LoginGetCart),
              "success"
            );
            runEngine.sendMessage(
              cartUpdateMessage.id,
              cartUpdateMessage
            );
            const cart_length = parseInt(
              localStorage.getItem("cart_length") ?? "0"
            );
            // @ts-ignore
            localStorage.setItem("cart_length", cart_length + 1);
            // this.setState({
            //   isSubscribeClicked: false,
            //   productDescriptionLoader: false,
            // });
          }
        }else if (apiRequestCallId === this.putItemToCartApiCallId) {
          this.setState({
            cartDetails: [responseJson.data],
            cartId: responseJson.data.id,
          });
          // @ts-ignore
          window.notify([{ message: "Item quantity updated in cart successfully", type: "success" }]);
          // @ts-ignore
          //this.getIsCartCreated()
          var cartUpdateMessage = new Message(
            getName(MessageEnum.LoginGetCart)
          );
          cartUpdateMessage.addData(
            getName(MessageEnum.LoginGetCart),
            "success"
          );
          runEngine.sendMessage(
            cartUpdateMessage.id,
            cartUpdateMessage
          );
          const cart_length = parseInt(
            localStorage.getItem("cart_length") || "" 
          );
          // @ts-ignore
          localStorage.setItem("cart_length", cart_length + 1);
          
        }else if (apiRequestCallId === this.GetIsCartCreatedApiCallId) {
          {
            responseJson?.data &&
              responseJson?.data?.length > 0 &&
              (this.setState({
                cartId: responseJson?.data[0]?.id,
                cartDetails: responseJson?.data,
              }),
              localStorage.setItem(
                "cart_length",
                responseJson?.data[0]?.attributes?.order_items?.length
              ));
          }
        }
      }
    }
  }

  //Email verfiyOTP
  sendEmailOTP = (values: any) => {
    const token = localStorage.getItem("token");
    this.setState({
      showSpinner: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendEmailOTPApiCallId = requestMessage.messageId;
    /* @ts-ignore */
    if (
      JSON.parse(localStorage.getItem("signUpUser"))?.accountType ==
      "PhoneAccount"
    ) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verfiyMobileOTPAPIEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verfiyEmailOTPAPIEndPoint
      );
    }

    const requestBody = {
      token: token,
      pin: values.otp,
    };
    const headers = {
      "content-type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  //resend OTP method
  resendOTP = () => {
    const user = JSON.parse(localStorage.getItem("signUpUser"));
    this.setState({
      otpToken: "",
    });
    let countryInfo = JSON?.parse(localStorage.getItem("countryCode") ?? "{}");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOTPEmailAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendEmailOTPAPIEndPoint
    );

    let requestBody: any;
    if (user.Email?.includes("@") || user.accountType == "EmailAccount") {
      requestBody = {
        data: {
          type: "email_account",
          process: "register",
          attributes: {
            email: user.Email,
          },
        },
      };
    } else {
      requestBody = {
        data: {
          type: "sms_account",
          process: "register",
          attributes: {
            full_phone_number: countryInfo?.countryPinCode + user.Email,
          },
        },
      };
    }
    let headers = {
      "content-type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  SignUp = () => {
    const user = JSON.parse(localStorage.getItem("signUpUser"));
    const token = localStorage.getItem("token");
    const userName = localStorage.getItem("userFullName");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerEmailAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailRegistrationAPIEndPoint
    );
    let requestBody: any;
    if (user.accountType == "PhoneAccount") {
      requestBody = {
        token: token,
        data: {
          type: "sms_account",
          attributes: {
            full_name: userName,
            full_phone_number:
              JSON.parse(localStorage.getItem("countryCode") ?? "{}")
                ?.countryPinCode + user.Email,
            password: user.password,
          },
        },
      };
    } else {
      requestBody = {
        token: token,
        data: {
          attributes: {
            first_name: userName,
            full_name: userName,
            email: user.Email,
            password: user.password,
          },
          type: "email_account",
          // process: "register",
        },
      };
    }
    const guestuserID = localStorage.getItem("guestUUID");
    if (guestuserID) {
      requestBody.uuid = guestuserID;
    }
    let headers = {
      "content-type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  addToCart = (product: any) => {
    this.state.cartId == "" && this.getIsCartCreated();
    this.setState({
      productToBeAdded: product,
    });
    
    setTimeout(() => {
      this.setState({
        productToBeAdded: product,
      });
      this.state.cartId != ""
        ? this.putItemToCart(this.state.cartId, "")
        : this.postCreateCart(product);
    }, 1000);
  };

  getIsCartCreated = (): boolean => {
    const headers = {
      "Content-Type": configJSON.getIsCartCreated,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetIsCartCreatedApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetIsCartCreated
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  postCreateCart = (product: any): boolean => {
    const { productDetails } = this.state;
    this.setState({ productDescriptionLoader: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    let httpBody: any;
      if (product?.catalogue_id && product?.catalogue_variant_id) {
        httpBody = {
          catalogue_id: product.catalogue_id,
          catalogue_variant_id: product.catalogue_variant_id,
          quantity: this.state.itemQuantity,
        };
      } else {
        httpBody = {
          catalogue_id: product.catalogue_id,
          // "catalogue_variant_id": product.id,
          quantity: this.state.itemQuantity,
        };
      }
      if(!localStorage.getItem("userData")){
        let cartCatelog = localStorage.getItem("cartCatelog")
        let cartVar = cartCatelog?JSON.parse(cartCatelog):[]
        cartVar.push(httpBody)
        localStorage.setItem('cartCatelog', JSON.stringify(cartVar))
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetIsCartCreated
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  putItemToCart = (cartId: any, type: string): boolean => {
    const product = this.state.productToBeAdded;
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    let httpBody: any;
      if (product.catalogue_id && product.catalogue_variant_id) {
        httpBody = {
          catalogue_id: product.catalogue_id,
          catalogue_variant_id: product.catalogue_variant_id,
          quantity: product.quantity,
        };
        localStorage.setItem(
          "catalogue_variant_id",
          product.catalogue_variant_id
        );
      } else if (
        !this.state.isProductAvailable == false &&
        this.state.productDetails?.attributes?.cart_quantity == null
      ) {
        httpBody = {
          catalogue_id: product.catalogue_id,
          quantity: product.quantity,
        };
      } else {
        httpBody = {
          catalogue_id: product.catalogue_id,
          quantity: this.state.itemQuantity,
        };
      }
      if(!localStorage.getItem("userData")){
      let cartCatelog = localStorage.getItem("cartCatelog")
      let cartVar = cartCatelog?JSON.parse(cartCatelog):[]
      cartVar.push(httpBody)
      localStorage.setItem('cartCatelog', JSON.stringify(cartVar))
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putItemToCartApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetIsCartCreated +'/'+ `${cartId}/add_item`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
